.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 12vh;
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: transparent;
    z-index: 20;
  }
  .logo {
    max-width: 100%;
    max-height: 6rem;
    cursor: pointer;
    padding: 0.5rem;
    margin-top: 0.5rem;
    position: relative;
  
  }
  .left-header {
    flex: 0.1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 4rem;
  }
  .right-header {
    padding-right: 4rem;
    flex: 0.8;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .nav-links {
    color: #d88a1e;
    font-size: 0.8rem;
    text-decoration: none;
    background-color: rgba(50, 50, 50, 0.5);
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    padding: 5px 15px;
    margin-left: 15px;
  }
  .nav-links:hover {
    background-color: rgb(0, 0, 0);
    color: #d88a1e;
  }
  .active {
    background-color: rgb(0, 0, 0);
  }
  .social {
    background-color: rgba(50, 50, 50, 0.5);
    font-size: 1.2rem;
  }
  .active:hover {
    background-color: rgba(0, 0, 0);
  }
  /* burger ------------------ */
  .burger {
    display: none;
    outline: none;
    height: 5px;
    width: 15px;
    border: 0px;
    padding: 0px;
    background: transparent;
    transition: all 250ms ease-out;
    cursor: pointer;
    position: relative;
    z-index: 5;
    transform: scale(1.5);
  }
  
  .burger:before,
  .burger:after {
    content: "";
    width: 15px;
    height: 2px;
    position: absolute;
    background: #D4A137;
    transition: all 250ms ease-out;
    will-change: transform;
  }
  
  .burger:before {
    transform: translateY(-3px);
  }
  
  .burger:after {
    transform: translateY(3px);
  }
  
  /* // line transition */
  
  .active.burger:before {
    transform: translateY(0) rotate(45deg);
  }
  
  .active.burger:after {
    transform: translateY(0) rotate(-45deg);
  }
  .social p {
    display: none;
  }
  @media screen and (max-width: 1253px) {
    .left-header {
      padding-left: 1rem;
    }
    .right-header {
      padding-right: 1rem;
    }
    .logo {
      size: 1em;
    }
    .header {
      max-height: 16vh;
    }
  }
  @media screen and (max-width: 1172px) {
    .burger {
      display: flex;
    }
    .right-header,
    .left-header {
      padding: 0;
    }
    .header {
      justify-content: space-between;
      padding-inline: 2.5rem;
      height: 10vh;
    }
    .right-header {
      padding: 0rem;
    }
    .burger {
      padding-right: 1rem;
    }
    .social p {
      display: block;
    }
    nav {
      position: absolute;
      height: 100vh;
      width: 100%;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.6);
      top: 0;
      justify-content: flex-start;
      padding-top: 13vh;
      padding-inline: 1.5rem;
      align-items: flex-start;
      z-index: -1;
      opacity: 0;
      transition: all 0.35s ease-in-out;
      pointer-events: none;
      left: 0;
      backdrop-filter: blur(16px);
    }
    .open {
      z-index: 2;
      opacity: 1;
      pointer-events: initial;
    }
    .active {
      background-color: transparent;
      text-decoration: none;
    }
    .nav-links {
      font-size: 1.5rem;
      font-weight: bolder;
      width: 95%;
      padding: 1rem 0rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      background-color: transparent;
      opacity: 1;
    }
    .nav-links:hover {
      background-color: transparent;
    }
    .social {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      max-height: 6rem;
      cursor: pointer;
      padding: 0rem;
      position: relative;
    
    }
  }
  