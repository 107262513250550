@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --whiteColor: #fff;
  --blackColor: #27272a;
  --grayColor: #e8c2ec;
}

html {
  overflow-x: hidden;
}
body {
  color: var(--whiteColor) !important;
  background-color: var(--blackColor) !important;
  font-family: "Montserrat", sans-serif !important;
  /* font-family: "Josefin Sans", sans-serif; */
  overflow: hidden;
  text-align: center;
}
h1 {
  font-size: 3.5em;
  font-weight: 700;
  text-align: center;
}

h1 span {
  -webkit-background-clip: text;
  color: transparent;
}
a .fas {
  transform: rotate(-40deg);
  margin-left: 0.5em;
}
p {
  /* font-family: "Manrope", sans-serif; */
  font-weight: 400;
  color: var(--whiteColor);
}
h2 {
  /* font-family: "Manrope", sans-serif; */
  font-weight: 700;
  font-size: 2.5em;
}

/* HOME SECTION  */
.Home {
  padding-top: 4em;
  min-height: 100vh;
  margin-left: 1em;
  margin-right: 1em;
}
.Home h1 {
  font-size: 2.5em;
  color: #eca739;
  text-align: center;
}
.Home p {
  text-transform: uppercase;
  margin: 0.5em 0;
}
.Home h6 {
  text-transform: uppercase;
  margin: 0.5em 0;
  font-size: small;
  font-weight: 700;
}


/* Footer */

.Footer {
  padding-top: 5em;

}
.Footer h1 {
  color: #d88a1e;
  padding-top: 1em;
  font-size: 2.5em;
}
.Footer h4 {
  font-size: 14px;
  font-style: italic;
}
.Footer p {
  font-size: 1.25em;
}
.Footer img {
  width: 200px;
}
.Footer a {
  text-decoration: none;
  display: inline-block;
  margin: 2em 1em;
  color: var(--whiteColor);
}
.Footer a .fab {
  font-size: 2em;
}

.About {
  padding: 0em 0;
  text-align: left;
  margin-bottom: 5em;
  margin-top: 5em;
}
.About .titleHeading {
  font-weight: 700;
  text-align: center;
}
.About h1 {
  color: #d88a1e;
}
.About h2 {
  color: #d88a1e;
}

.Contact {
  text-align: left;
  margin-bottom: 5em;
  margin-top: 5em;
}
.Contact .titleHeading {
  margin: 1em 0;
  font-weight: 700;
  text-align: center;
}
.Contact h1 {
  margin: 1em 0;
  color: #d88a1e;
}
.Contact h2 {
  color: #d88a1e;
}

.Delivery {
  padding: 0em 0;
  text-align: left;
  margin-top: 5em;
}
.Delivery .titleHeading {
  text-align: center;
}
.Delivery h1 {
  padding-bottom: 0.25em;
  color: #d88a1e;
}
.Delivery h2 {
  color: #d88a1e;
}
.Delivery img {
  padding-left: 5em;
  padding-right: 5em;
}

.Inventory {
  text-align: left;
  height: fit-content !important;
  margin-top: 5em;
  padding-bottom: 10em;
}
.Inventory .titleHeading {
  margin-top: 1em 0;
  font-weight: 700;
  text-align: center;
}
.Inventory h1 {
  margin-top: 1em 0;
  color: #d88a1e;
}
.Inventory h5 {
  text-align: center;
}
.Inventory h2 {
  color: #ffffff;
  text-align: center;
  font-size: medium;
  padding-bottom: 3em;
}
.Inventory h4 {
  color: #ffffff;
  text-align: center;
  font-size: medium;
  padding-top: 3em;
}
.Inventory .center {
  margin-left: 5em;
  margin-right: 5em;
}

.Photos {
  padding-top: 5em;
  text-align: left;
  margin-top: 5em;
}
.Photos .titleHeading {
  font-weight: 700;
  text-align: center;
}
.Photos h1 {
  color: #d88a1e;
}
.Photos h2 {
  color: #d88a1e;
}
.Photos p {
  color: #ffffff;
  text-align: center;
  padding-bottom: 5em;
}
.Photos .bg-img {
  width: 100vw;
  justify-content: center;
  display: flex;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
}

