.center {
    position: absolute;
    align-items: center;
}
.Team p {
    color: rgb(96, 96, 96);
}
.Team h1 {
    color: rgb(200, 200, 200);
}
.Team h3 {
    color: rgb(200, 200, 200);
    padding-top: 1em;
}